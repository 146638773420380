// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src272581605/src/marigold-photography/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-pages-about-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/instagram.tsx" /* webpackChunkName: "component---src-pages-instagram-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("/codebuild/output/src272581605/src/marigold-photography/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

